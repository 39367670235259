import React from "react";
import get from "lodash/get";

import { graphql, Link } from "gatsby";

import Layout from "../components/global/layout";
import Container from "../components/global/container";
import InnerPageHeader from "../components/inner-page-header/inner-page-header";

class Terms extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;

        return (
            <Layout
                title={`Terms Of Use | ${meta.siteMetadata.title}`}
                location={location}
            >
                <InnerPageHeader pageTitle="Terms Of Use" />
                <article>
                    <section className="py-60 background-black text-white content">
                        <Container>
                            <div className="row">
                                <div className="col-12 col-md-10 offset-md-1">
                                    <p>
                                        Last updated:{" "}
                                        <strong>May 20, 2021</strong>
                                    </p>
                                    <p>
                                        PLEASE READ THE FOLLOWING TERMS AND
                                        CONDITIONS OF SERVICE (“TOS”) BEFORE
                                        USING THE SERVICES. If you do not agree
                                        with the following terms and conditions,
                                        do not download or install this
                                        software. By downloading, installing or
                                        using this software or any portion
                                        thereof, you signify YOUR ACCEPTANCE OF
                                        THE TOS WITHOUT LIMITATION, THE
                                        ARBITRATION AGREEMENT AND CLASS ACTION
                                        WAIVER IN PARAGRAPH 15 BELOW.
                                    </p>
                                    <p>
                                        These TOS set forth the legal terms and
                                        conditions governing your use of our
                                        website, interactive application(s),
                                        such as “Unifyd” (the “App”) and any other
                                        online and mobile websites, software,
                                        along with the content, services,
                                        activities, and transactions (unless a
                                        different policy is provided on a
                                        particular site, application or service,
                                        in which case such different policy
                                        shall govern and control) (collectively,
                                        the “Services”) operated and made
                                        available by Unifyd. (“Unifyd,” “we,” “our”).
                                    </p>
                                    <h2 id="user-consent-to-the-tos">
                                        1. User Consent to the TOS
                                    </h2>
                                    <p>
                                        You represent that you have read and
                                        agree to be bound by the TOS. You
                                        signify your acceptance to the TOS by
                                        doing any one of the following:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                Accessing, downloading, linking
                                                to or using the Services
                                            </p>
                                        </li>
                                        <li>
                                            <p>Clicking to “Accept” the TOS</p>
                                        </li>
                                    </ul>
                                    <p>
                                        Unifyd reserves the right to amend, remove
                                        or add to the TOS at any time. Such
                                        modifications shall be effective
                                        immediately upon posting. It is your
                                        responsibility to review the TOS prior
                                        to your uses of the Services, and as
                                        your continued use of the Services after
                                        any such changes constitutes your
                                        agreement to such changes. Your access,
                                        link to, or use of the site, or any
                                        Services or App, after the posting of
                                        modifications to the TOS will constitute
                                        YOUR ACCEPTANCE OF THE TOS, as modified.
                                        If, at any time, you do not wish to
                                        accept the TOS, you may not access, link
                                        to, or use the site or App. Any terms
                                        and conditions proposed by you which are
                                        in addition to or which conflict with
                                        the TOS are expressly rejected by Unifyd
                                        and shall be of no force or effect.
                                    </p>
                                    <h2 id="your-authority-to-agree-to-this-tos">
                                        2. Your Authority to Agree to this TOS
                                    </h2>
                                    <p>
                                        You represent, warrant and covenant that
                                        you have reached the age of majority in
                                        the jurisdiction where you live (in most
                                        U.S. states, that’s 18 years old) or be
                                        over 13 and have valid consent from your
                                        parent or legal guardian to use the
                                        Services and be bound by TOS. If you are
                                        the parent or legal guardian of a minor
                                        that creates an account, you accept the
                                        TOS on the minor’s behalf and are
                                        jointly responsible with the minor for
                                        all use of the Services and compliance
                                        with the TOS.
                                    </p>
                                    <h2 id="intellectual-property-and-related-rights">
                                        3. Intellectual Property and Related
                                        Rights
                                    </h2>
                                    <p>
                                        The Services, inclusive of the site and
                                        App and all of the content it contains,
                                        or may in the future contain, including
                                        but not limited to text, content,
                                        photographs, video, audio and graphics,
                                        goods, designs, information,
                                        applications, software, music, audio
                                        files, articles, directories, guides,
                                        photographs as well as the trademarks,
                                        service marks, trade names, trade dress,
                                        copyrights, logos, domain names, code,
                                        patents and/or any other form of
                                        intellectual property that relates to
                                        this site, are owned by or licensed by
                                        Unifyd or other third parties and are
                                        protected from any unauthorized use,
                                        copying and dissemination by copyrights,
                                        trademarks, service marks, international
                                        treaties, and/or other proprietary
                                        rights and laws of the U.S. and other
                                        countries. The Services is also
                                        protected as a collective work or
                                        compilation under U.S. copyright and
                                        other laws and treaties. All individual
                                        articles, columns and other elements
                                        making up the Services are also
                                        copyrighted works. You agree to abide by
                                        all applicable copyright and other laws,
                                        as well as any additional copyright
                                        notices or restrictions contained in the
                                        Services. You acknowledge that the
                                        Services has been developed, compiled,
                                        prepared, revised, selected, and
                                        arranged by Unifyd, its parent company and
                                        affiliates (collectively “Unifyd Group
                                        Companies”) and others (including
                                        certain other information sources)
                                        through the application of methods and
                                        standards of judgment developed and
                                        applied through the expenditure of
                                        substantial time, effort, and money and
                                        constitutes valuable intellectual
                                        property of Unifyd Group Companies and such
                                        others. You agree to notify Unifyd in
                                        writing promptly upon becoming aware of
                                        any unauthorized access or use of the
                                        Services by any individual or entity or
                                        of any claim that the Services infringes
                                        upon any copyright, trademark, or other
                                        contractual, statutory, or common law
                                        rights. All present and future rights in
                                        and to trade secrets, patents,
                                        copyrights, trademarks, service marks,
                                        know-how, and other proprietary rights
                                        of any type under the laws of any
                                        governmental authority, domestic or
                                        foreign, including rights in and to all
                                        applications and registrations relating
                                        to the Services (the “Intellectual
                                        Property Rights”) shall, as between you
                                        and Unifyd, at all times be and remain the
                                        sole and exclusive property of Unifyd. All
                                        present and future rights in and title
                                        to the Services (including the right to
                                        exploit the Services and any portions of
                                        the Services over any present or future
                                        technology) are reserved to Unifyd for its
                                        exclusive use. Except as specifically
                                        permitted by the TOS, you may not copy
                                        or make any use of the Services or any
                                        portion thereof. Except as specifically
                                        permitted herein, you shall not use the
                                        Intellectual Property Rights or the
                                        Services, or the names of any individual
                                        participant in, or contributor to, the
                                        Services, or any variations or
                                        derivatives thereof, for any purpose,
                                        without Unifyd’s prior written approval.
                                    </p>
                                    <p>
                                        Trademarks. Unifyd’s stylized name and
                                        other related graphics, logos, service
                                        marks and trade names used on or in
                                        connection with the Service are the
                                        trademarks of Unifyd and may not be used
                                        without permission in connection with
                                        any third-party products or services.
                                        Other trademarks, service marks and
                                        trade names that may appear on or in the
                                        Service are property of their respective
                                        owners. You will not remove, alter or
                                        obscure any copyright notice, trademark,
                                        service mark or other proprietary rights
                                        notices incorporated in or accompanying
                                        the Service.
                                    </p>
                                    <h2 id="license-and-restrictions-on-use">
                                        4. License and Restrictions on Use
                                    </h2>
                                    <p>
                                        You acquire absolutely no rights or
                                        licenses in or to the Services and
                                        materials contained within the Services
                                        other than the limited right to utilize
                                        the Services in accordance with the TOS.
                                        Should you choose to download content
                                        from the Services, you must do so in
                                        accordance with the TOS. Such download
                                        is licensed to you by Unifyd ONLY for your
                                        own personal, noncommercial use in
                                        accordance with the TOS and does not
                                        transfer any other rights to you.
                                    </p>
                                    <p>
                                        You may not use the Services for any
                                        illegal purpose, for the facilitation of
                                        the violation of any law or regulation,
                                        or in any manner inconsistent with the
                                        TOS. You agree to use the Services
                                        solely for your own noncommercial use
                                        and benefit, and not for resale or other
                                        transfer or disposition to, or use by or
                                        for the benefit of, any other person or
                                        entity. You agree not to use, transfer,
                                        distribute, or dispose of any
                                        information contained in the Services in
                                        any manner that could compete with the
                                        business of Unifyd or any of its suppliers.
                                    </p>
                                    <p>
                                        Except as expressly permitted by Unifyd in
                                        writing you may not: copy, reproduce,
                                        recompile, decompile, disassemble,
                                        reverse engineer, distribute, publish,
                                        display, perform, modify, upload to,
                                        create derivative works from, transmit,
                                        transfer, sell, license, upload, edit
                                        post, frame, link, or in any way exploit
                                        any part of the Services, except that
                                        you may download material from the
                                        Services and/or make one print copy for
                                        your own personal, noncommercial use,
                                        provided that you retain all copyright
                                        and other proprietary notices. You may
                                        not recirculate, redistribute or publish
                                        the analysis and presentation included
                                        in the Services without Unifyd’s prior
                                        written consent. Nothing contained in
                                        the TOS or on this site should be
                                        construed as granting, by implication,
                                        estoppel or otherwise, any license or
                                        right to use any Services in any manner
                                        without the prior written consent of Unifyd
                                        or such third party that may own the
                                        Services or intellectual property
                                        displayed on this site. UNAUTHORIZED
                                        USE, COPYING, REPRODUCTION,
                                        MODIFICATION, PUBLICATION,
                                        REPUBLICATION, UPLOADING, FRAMING,
                                        DOWNLOADING, POSTING, TRANSMITTING,
                                        DISTRIBUTING, DUPLICATING OR ANY OTHER
                                        MISUSE OF ANY OF THE SERVICES IS
                                        STRICTLY PROHIBITED. Any use of the
                                        Services other than as permitted by the
                                        TOS will violate the TOS and may
                                        infringe upon our rights or the rights
                                        of the third party that owns the
                                        affected Service. You agree to report
                                        any violation of the TOS by others that
                                        you become aware of.
                                    </p>
                                    <p>
                                        You may neither interfere or attempt to
                                        interfere with or otherwise disrupt the
                                        proper working of Services, any
                                        activities conducted on or through the
                                        Services or any servers or networks
                                        connected to the Services. You may
                                        neither (a) attempt to access, search or
                                        otherwise use the Services (such as by
                                        attempting to retrieve information from
                                        or about the Services) through the use
                                        of any engine, software, tool, agent,
                                        device or mechanism (including spiders,
                                        scrapers, robots, crawlers, data mining
                                        tools or the like) other than (i)
                                        generally available third-party web
                                        browsers that provide accurate and
                                        complete User-Agent information in the
                                        HTTP header, such as Chrome, Safari,
                                        Firefox and Internet Explorer and (ii)
                                        our apps, (b) attempt to access any API
                                        we may provide without first obtaining
                                        our written permission and agreeing to
                                        our API-specific terms, © access our
                                        Services using anything that has been
                                        created (or modified with plugins or
                                        otherwise) in a manner that facilitates
                                        automated or faster-than-normal access
                                        to, searching of or other use of the
                                        Services, (d) disregard any robots.txt
                                        or similar files that we employ (but in
                                        the event of a conflict between these
                                        TOS and a robots.txt file, the relevant
                                        provisions of the one that is more
                                        restrictive of your actions shall
                                        apply), or (e) display to others, mirror
                                        or frame the Services, or any individual
                                        element within the Services, except that
                                        you may use embedding tools that we may
                                        choose to provide specifically to permit
                                        the embedding of aspects of our
                                        Services, consistent with any additional
                                        restrictions we impose for the use of
                                        such tools.
                                    </p>
                                    <p>
                                        You may not input, distribute, load,
                                        post, email, transmit or otherwise make
                                        available any content through the
                                        Services that: (i) is promotional in
                                        nature, including unsolicited or
                                        unauthorized advertising, junk email,
                                        spam or other form of solicitation, (ii)
                                        is unlawful, harmful, abusive,
                                        harassing, tortious, defamatory, vulgar,
                                        obscene, libelous, invasive of another’s
                                        privacy, hateful, or racially,
                                        ethnically or otherwise objectionable;
                                        (iii) infringes, misappropriates or
                                        violates a third party’s copyright,
                                        trademark, trade secret, patent, moral
                                        rights or other intellectual property
                                        rights, rights of publicity or privacy;
                                        (iv) violates, or encourages any conduct
                                        that would violate, any legal
                                        requirement; (v) is fraudulent, false,
                                        misleading or deceptive; (vi) contains
                                        software viruses or any other computer
                                        code, files or programs designed to
                                        interrupt, destroy or limit the
                                        functionality of any computer software
                                        or hardware, or telecommunications
                                        equipment; (vii) is harmful to minors;
                                        or (viii) constitutes the personally
                                        identifiable information of any other
                                        person that such person has not
                                        authorized you to disclose
                                    </p>
                                    <p>
                                        You shall not violate the security of
                                        the Services, attempt to probe, scan, or
                                        test the vulnerability of the Services
                                        or breach any security or authentication
                                        measures, or attempt to gain
                                        unauthorized access to the Services,
                                        data, materials, information, computer
                                        systems or networks connected to any
                                        server associated with the Services,
                                        through hacking, password timing or any
                                        other means. You shall not use or
                                        attempt to use any “scraper,” “robot,”
                                        “bot,” “spider,” “data mining,”
                                        “computer code,” or any other automate
                                        device, program, tool, algorithm,
                                        process or methodology to access,
                                        acquire, copy, or monitor any portion of
                                        the Services, any data or content found
                                        on or accessed through the Services, or
                                        any other Services information without
                                        the prior express written consent of
                                        Unifyd. You may not interfere with, or
                                        attempt to interfere with, the access of
                                        any user, host or network, including,
                                        without limitation, by sending a virus,
                                        overloading, flooding, spamming, or
                                        mail-bombing the Services. You may not
                                        Access the Service if we have requested
                                        that you refrain from such access.
                                    </p>
                                    <h2 id="change-in-services">
                                        5. Change in Services
                                    </h2>
                                    <p>
                                        We reserve the right to change and
                                        improve the features and functionality
                                        of the Services at any time and at our
                                        sole discretion. This includes adding,
                                        modifying or removing specific features
                                        and functionality of the Services, or
                                        updating our App or other Services
                                        software (including remotely). The TOS
                                        will apply to the changed version of the
                                        Services. Additionally, we reserve the
                                        right to suspend or stop the Services
                                        altogether for any reason or no reason,
                                        without notice, at any time, and without
                                        liability to you.
                                    </p>
                                    <h2 id="third-party-content-and-links-to-third-party-websites">
                                        6. Third-Party Content and Links to
                                        Third-Party Websites
                                    </h2>
                                    <p>
                                        If at any time the Services contain
                                        links to third-party websites,
                                        resources, and advertisers
                                        (collectively, “Linked Content”). Unifyd
                                        does not control, endorse, sponsor,
                                        recommend or otherwise accept
                                        responsibility for any of this Linked
                                        Content. Because we are not responsible
                                        for the availability of these outside
                                        resources, or their contents or privacy
                                        practices, you should direct any
                                        concerns regarding any Linked Content to
                                        such site.
                                    </p>
                                    <h2 id="fees-and-payments">
                                        7. Fees and Payments
                                    </h2>
                                    <p>
                                        Unifyd reserves the right at any time to
                                        charge fees for access to portions of
                                        the Services or the Services as a whole.
                                    </p>
                                    <p>
                                        If at any time Unifyd requires a fee for
                                        portions of the Services or the Services
                                        as a whole, Unifyd may require you to
                                        register and create an account. The
                                        decision to provide the information
                                        required to create an account is purely
                                        voluntary and optional; however, if you
                                        elect not to provide such information,
                                        you will not be able to access certain
                                        content or participate in certain parts
                                        or features of this site. You shall pay
                                        all fees and charges incurred through
                                        your account at the rates in effect for
                                        the billing period in which such fees
                                        and charges are incurred, including but
                                        not limited to charges for any products
                                        or services offered for sale through the
                                        Services by Unifyd or by any other vendor
                                        or service provider. All fees and
                                        charges shall be billed to you, and you
                                        shall be solely responsible for their
                                        payment. You shall pay all applicable
                                        taxes relating to the use of the
                                        Services through your account, and the
                                        purchase of any other products or
                                        services.
                                    </p>
                                    <h2 id="privacy-policy">
                                        8. Privacy Policy
                                    </h2>
                                    <p>
                                        You consent to the collection, use,
                                        disclosure and other handling of
                                        information described in our Privacy
                                        Policy located
                                        <Link to="/policy">here</Link>, which we
                                        may update from time to time.
                                    </p>
                                    <h2 id="communications-decency-act-notice">
                                        9. Communications Decency Act Notice
                                    </h2>
                                    <p>
                                        Commercially available parental control
                                        protections (such as computer hardware,
                                        software or filtering services) may
                                        assist you in limiting access to
                                        material on the Internet that is harmful
                                        to minors. Current providers of such
                                        protections include McAfee and Symantec,
                                        as well as others available from{" "}
                                        <a href="https://www.google.com/?gws_rd=ssl#q=parental+control+software">
                                            a Google search
                                        </a>
                                        . Please note that we did not create
                                        these parental control tools, we have
                                        not tested or evaluated them and we do
                                        not endorse them. Any use of such tools
                                        is at your sole risk. You should not
                                        assume that these or any other
                                        third-party parental control protections
                                        will work well or at all in connection
                                        with the Services.
                                    </p>
                                    <h2 id="digital-millennium-copyright-act-notice">
                                        10. Digital Millennium Copyright Act
                                        Notice
                                    </h2>
                                    <p>
                                        We are committed to complying with U.S.
                                        copyright and related laws, and we
                                        require all users of the Services to
                                        comply with these laws. Accordingly, our
                                        users (including you) may not
                                        disseminate any material or content
                                        using the Services in any manner that
                                        constitutes an infringement of
                                        third-party intellectual property
                                        rights, including rights granted by U.S.
                                        copyright law. Owners of copyrighted
                                        works who believe that their rights
                                        under U.S. copyright law have been
                                        infringed may take advantage of certain
                                        provisions of the Digital Millennium
                                        Copyright Act of 1998 (the “DMCA”) to
                                        report alleged infringements. It is our
                                        policy in accordance with the DMCA and
                                        other applicable laws to reserve the
                                        right to terminate the rights of any
                                        user to access the Services if any such
                                        user is either found to infringe third
                                        party copyright or other intellectual
                                        property rights, including repeat
                                        infringers, or who we believe, in our
                                        sole discretion, are infringing these
                                        rights. Upon our receipt of a proper
                                        notice of claimed infringement under the
                                        DMCA, we will respond expeditiously to
                                        remove, or disable access to, the
                                        material claimed to be infringing and
                                        will follow the procedures specified in
                                        the DMCA to resolve the claim between
                                        the notifying party and the alleged
                                        infringer who provided the content in
                                        issue. Our designated agent (i.e., the
                                        proper party) to whom you should address
                                        such notice is:{" "}
                                        <a href="https://unifyd.finance">
                                            unifyd.finance
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        If you believe that content that you or
                                        a third party owns has been used via the
                                        Services in a way that violates your or
                                        someone else’s copyright or other
                                        intellectual property rights, please
                                        provide us with the following
                                        information:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                an electronic or physical
                                                signature of the person
                                                authorized to act on behalf of
                                                the owner of the copyright or
                                                other intellectual property
                                                interest;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                a description of the copyrighted
                                                work or other intellectual
                                                property that you claim has been
                                                infringed;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                a description of where the
                                                material that you claim is
                                                infringing is located;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                your address, telephone number,
                                                and email address;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                a statement by you that you have
                                                a good faith belief that the
                                                disputed use is not authorized
                                                by the copyright or intellectual
                                                property owner, its agent, or
                                                the law; and
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                a statement by you, made under
                                                penalty of perjury, that the
                                                information contained in your
                                                report is accurate and that you
                                                are the copyright or
                                                intellectual property owner or
                                                authorized to act on the
                                                copyright or intellectual
                                                property owner’s behalf.
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        If you submit a notice of infringement
                                        that knowingly materially misrepresents
                                        that any content, information, or
                                        communication on the Services is
                                        infringing upon a copyright, you may be
                                        held liable for damages and attorneys’
                                        fees. If you believe that your content,
                                        information, or communication has been
                                        removed from the site due to an
                                        erroneous claim of infringement, you may
                                        have remedies available to you under the
                                        DMCA.
                                    </p>
                                    <h2 id="indemnification">
                                        11. Indemnification
                                    </h2>
                                    <p>
                                        You agree, at your own expense, to
                                        indemnify and hold harmless Unifyd, Unifyd
                                        Corporate Group and their agents,
                                        partners, employees, contractors and
                                        advertisers, from and against any
                                        damages, loss, costs, settlements,
                                        expenses and payments, including
                                        reasonable attorney’s fees and legal
                                        expenses, resulting from any third-party
                                        complaint, claim, demand or liability
                                        arising out of or related to your acts
                                        or omissions in relation to the
                                        Services, your breach of these TOS, or
                                        your violation of any law or third party
                                        rights. You agree to pay any and all
                                        costs, damages, and expenses, including,
                                        but not limited to, reasonable
                                        attorneys’ fees and costs awarded
                                        against or otherwise incurred by or in
                                        connection with or arising from any such
                                        claim, suit, action, or proceeding
                                        attributable to any such claim. Unifyd
                                        reserves the right, at its own expense,
                                        to assume the exclusive defense and
                                        control of any matter otherwise subject
                                        to indemnification by you, in which
                                        event you will fully cooperate with Unifyd
                                        in asserting any available defense. You
                                        acknowledge and agree to pay Unifyd’s
                                        reasonable attorneys’ fees incurred in
                                        connection with any and all lawsuits
                                        brought against you by Unifyd under the TOS
                                        and any other terms and conditions of
                                        service on this site, including without
                                        limitation, lawsuits arising from your
                                        failure to indemnify Unifyd pursuant to the
                                        TOS. The members of Unifyd Corporate Group,
                                        and the agents, partners, employees,
                                        contractors and advertisers of them and
                                        of Unifyd, are third-party beneficiaries of
                                        this paragraph.
                                    </p>
                                    <h2 id="disclaimer-of-representations-and-warranties">
                                        12. Disclaimer of Representations and
                                        Warranties
                                    </h2>
                                    <p>
                                        THIS DISCLAIMER SECTION IS AN ESSENTIAL
                                        PART OF THIS AGREEMENT.
                                    </p>
                                    <p>
                                        YOUR USE OF THE SERVICES IS AT YOUR OWN
                                        RISK. THE SERVICES IS PROVIDED ON AN “AS
                                        IS” AND “AS AVAILABLE” BASIS. WE
                                        DISCLAIM ALL WARRANTIES AND
                                        REPRESENTATIONS, EITHER EXPRESS OR
                                        IMPLIED, WITH RESPECT TO THE SERVICES,
                                        INCLUDING, WITHOUT LIMITATION, ANY
                                        WARRANTIES (1) OF MERCHANTABILITY OR
                                        FITNESS FOR A PARTICULAR PURPOSE, (2) OF
                                        INFORMATIONAL CONTENT OR ACCURACY, (3)
                                        OF NON-INFRINGEMENT, (4) OF PERFORMANCE,
                                        (5) OF TITLE, (6) THAT THE SERVICES WILL
                                        OPERATE IN AN ERROR FREE, TIMELY,
                                        SECURE, OR UNINTERRUPTED MANNER, IS
                                        CURRENT AND UP TO DATE AND ACCURATELY
                                        DESCRIBES ANYTHING, OR IS FREE OF
                                        VIRUSES OR OTHER HARMFUL COMPONENTS, (7)
                                        THAT ANY DEFECTS OR ERRORS IN THE
                                        SERVICES WILL BE CORRECTED, (8) THAT THE
                                        SERVICES IS COMPATIBLE WITH ANY
                                        PARTICULAR HARDWARE OR SOFTWARE
                                        PLATFORM, OR (9) THAT WE WILL ENFORCE
                                        THE TOS AGAINST OTHERS TO YOUR
                                        SATISFACTION. EFFORTS BY UNIFYD TO MODIFY
                                        THE SERVICES SHALL NOT BE DEEMED A
                                        WAIVER OF THESE LIMITATIONS OR ANY OTHER
                                        PROVISION OF THE TOS. Some jurisdictions
                                        limit or do not allow the disclaimer of
                                        implied warranties, and as a result some
                                        or all of this section may not apply to
                                        you. In cases where such laws apply, the
                                        warranties will be disclaimed only to
                                        fullest extent permitted by law.
                                    </p>
                                    <h2 id="limitation-of-liability">
                                        13. Limitation of Liability
                                    </h2>
                                    <p>
                                        IN NO EVENT SHALL UNIFYD, UNIFYD CORPORATE
                                        GROUP, OR ITS OFFICERS, DIRECTORS,
                                        AGENTS, EMPLOYEES, REPRESENTATIVES,
                                        INTERNAL OPERATING UNITS, AFFILIATES,
                                        PARENTS, SUBSIDIARIES, SUBLICENSEES,
                                        SUCCESSORS AND ASSIGNS, INDEPENDENT
                                        CONTRACTORS, AND RELATED PARTIES
                                        (COLLECTIVELY, WITH UNIFYD, AND THE UNIFYD
                                        CORPORATE GROUP, THE “UNIFYD ENTITIES”) BE
                                        LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                                        LOSS OF PROFITS, LOSS OF USE, LOSS OF
                                        DATA, INTERRUPTION OF BUSINESS, OR ANY
                                        INDIRECT, INCIDENTAL, SPECIAL OR
                                        CONSEQUENTIAL DAMAGES ARISING OUT OF OR
                                        IN ANY WAY CONNECTED WITH THE USE OF THE
                                        SERVICES OR WITH THE DELAY OR INABILITY
                                        TO USE SAME, OR FOR ANY BREACH OF
                                        SECURITY, OR FOR ANY CONTENT, PRODUCTS,
                                        AND SERVICES OBTAINED THROUGH OR VIEWED
                                        ON THE SERVICES, OR OTHERWISE ARISING
                                        OUT OF THE USE OF SAME, WHETHER BASED ON
                                        CONTRACT, TORT, STRICT LIABILITY,
                                        REGULATION, COMMON LAW PRECEDENT OR
                                        OTHERWISE, EVEN IF THE UNIFYD ENTITIES HAVE
                                        BEEN ADVISED OF THE POSSIBILITY OF
                                        DAMAGES AND EVEN IF SUCH DAMAGES RESULT
                                        FROM ANY UNIFYD ENTITY’S NEGLIGENCE OR
                                        GROSS NEGLIGENCE. IN NO EVENT SHALL THE
                                        UNIFYD ENTITIES’ AGGREGATE LIABILITY FOR
                                        ALL CLAIMS RELATING TO THE SERVICES
                                        EXCEED ONE HUNDRED U.S. DOLLARS (U.S.
                                        $100.00). ADDITIONAL DISCLAIMERS BY UNIFYD
                                        APPEAR WITHIN THE SERVICES AND ARE
                                        INCORPORATED HEREIN BY REFERENCE. TO THE
                                        EXTENT ANY SUCH DISCLAIMERS PLACE
                                        GREATER RESTRICTIONS ON YOUR USE OF THE
                                        SERVICES OR THE MATERIAL CONTAINED
                                        THEREIN, SUCH GREATER RESTRICTIONS SHALL
                                        APPLY. Some jurisdictions restrict or do
                                        not allow the limitation of liability in
                                        contracts, and as a result the contents
                                        of this section may not apply to you. In
                                        cases where such laws apply, liability
                                        of the Unifyd Entities shall be limited to
                                        the fullest extent permitted by law.
                                    </p>
                                    <p>
                                        BY ACCESSING THIS SITE, YOU UNDERSTAND
                                        THAT YOU MAY BE WAIVING RIGHTS WITH
                                        RESPECT TO CLAIMS THAT ARE AT THIS TIME
                                        UNKNOWN OR UNSUSPECTED, AND IN
                                        ACCORDANCE WITH SUCH WAIVER, YOU
                                        ACKNOWLEDGE THAT YOU HAVE READ AND
                                        UNDERSTAND, AND HEREBY EXPRESSLY WAIVE,
                                        THE BENEFITS OF SECTION 1542 OF THE
                                        CIVIL CODE OF CALIFORNIA, AND ANY
                                        SIMILAR LAW OF ANY STATE OR TERRITORY,
                                        WHICH PROVIDES AS FOLLOWS:
                                    </p>
                                    <p>
                                        “A GENERAL RELEASE DOES NOT EXTEND TO
                                        CLAIMS WHICH THE CREDITOR DOES NOT KNOW
                                        OR SUSPECT TO EXIST IN HIS OR HER FAVOR
                                        AT THE TIME OF EXECUTING THE RELEASE,
                                        WHICH IF KNOWN BY HIM OR HER MUST HAVE
                                        MATERIALLY AFFECTED HIS OR HER
                                        SETTLEMENT WITH THE DEBTOR.”
                                    </p>
                                    <p>
                                        You hereby waive any and all rights you
                                        have or may have under California Civil
                                        Code Section 1542, and/or any similar
                                        provision of law or successor statute to
                                        it, with respect to any claims you may
                                        have in connection with this site or
                                        these TOS. In connection with this
                                        waiver and release, you acknowledge that
                                        you are aware that you may hereafter
                                        discover claims presently unknown or
                                        unsuspected, or facts in addition to or
                                        different from those which you now know
                                        or believe to be true. Nevertheless, you
                                        intend by these TOS to release fully,
                                        finally and forever all such matters
                                        under these TOS In furtherance of such
                                        intention, the releases set forth in
                                        these TOS shall be and shall remain in
                                        effect as full and complete releases
                                        notwithstanding the discovery or
                                        existence of any such additional or
                                        different claims or facts relevant
                                        hereto.
                                    </p>
                                    <p>
                                        If you are a resident of New Jersey,
                                        this section does not apply to prevent a
                                        right to recover certain damages
                                        (including punitive damages) where a
                                        harmed person proves with the required
                                        evidence that the harm suffered was the
                                        result of the defendant’s “acts or
                                        omissions and such acts or omissions
                                        were actuated by actual malice or
                                        accompanied by a wanton and willful
                                        disregard of persons who foreseeably
                                        might be harmed by those acts or
                                        omissions.” Similarly, this section does
                                        not limit Unifyd’s tort liability under New
                                        Jersey law resulting from Unifyd’s own
                                        intentional or reckless conduct.
                                    </p>
                                    <h2 id="governing-law">
                                        14. Governing Law
                                    </h2>
                                    <p>
                                        The TOS shall be governed and construed
                                        in accordance with the laws of the
                                        United States and the State of New York,
                                        without giving effect to
                                        conflicts-of-law principles thereof.
                                        With respect to any disputes or claims
                                        not subject to arbitration, you agree to
                                        submit to the personal jurisdiction of
                                        the state and federal courts located in
                                        New York County in the State of New York
                                        with respect to any legal proceedings
                                        that may arise in connection with the
                                        Services or from a dispute as to the
                                        interpretation or breach of the TOS.
                                    </p>
                                    <h2 id="arbitration">15. Arbitration</h2>
                                    <p>
                                        By using this site in any way, you
                                        unconditionally consent and agree that:
                                        (1) any claim, dispute, or controversy
                                        (whether in contract, tort, or
                                        otherwise) you may have against Unifyd
                                        and/or Unifyd Entities arising out of,
                                        relating to, or connected in any way
                                        with the website or the determination of
                                        the scope or applicability of this
                                        agreement to arbitrate, will be resolved
                                        exclusively by final and binding
                                        arbitration administered by JAMS and
                                        conducted before a sole arbitrator in
                                        accordance with the rules of JAMS; (2)
                                        this arbitration agreement is made
                                        pursuant to a transaction involving
                                        interstate commerce, and shall be
                                        governed by the Federal Arbitration Act
                                        (“FAA”), 9 U.S.C. §§ 1-16; (3) the
                                        arbitration shall be held in New York
                                        City, New York; (4) the arbitrator’s
                                        decision shall be controlled by the
                                        terms and conditions of these TOS and
                                        any of the other agreements referenced
                                        herein that the applicable user may have
                                        entered into in connection with the
                                        website; (5) the arbitrator shall apply
                                        New York law consistent with the FAA and
                                        applicable statutes of limitations, and
                                        shall honor claims of privilege
                                        recognized at law; (6) there shall be no
                                        authority for any claims to be
                                        arbitrated on a class or representative
                                        basis, arbitration can decide only your
                                        and/or the applicable Unifyd Entity’s
                                        individual claims, and the arbitrator
                                        may not consolidate or join the claims
                                        of other persons or parties who may be
                                        similarly situated; (7) the arbitrator
                                        shall not have the power to award
                                        punitive damages against you or any Unifyd
                                        Entity; (8) in the event that the
                                        administrative fees and deposits that
                                        must be paid to initiate arbitration
                                        against any Unifyd Entity exceed $125 USD,
                                        and you are unable (or not required
                                        under the rules of JAMS) to pay any fees
                                        and deposits that exceed this amount,
                                        Unifyd agrees to pay them and/or forward
                                        them on your behalf, subject to ultimate
                                        allocation by the arbitrator. In
                                        addition, if you are able to demonstrate
                                        that the costs of arbitration will be
                                        prohibitive as compared to the costs of
                                        litigation, Unifyd will pay as much of your
                                        filing and hearing fees in connection
                                        with the arbitration as the arbitrator
                                        deems necessary to prevent the
                                        arbitration from being cost-prohibitive;
                                        and (9) with the exception of subpart
                                        (6) above, if any part of this
                                        arbitration provision is deemed to be
                                        invalid, unenforceable or illegal, or
                                        otherwise conflicts with the rules of
                                        JAMS, then the balance of this
                                        arbitration provision shall remain in
                                        effect and shall be construed in
                                        accordance with its terms as if the
                                        invalid, unenforceable, illegal or
                                        conflicting provision were not contained
                                        herein. If, however, subpart (6) is
                                        found to be invalid, unenforceable or
                                        illegal, then the entirety of this
                                        Arbitration Provision shall be null and
                                        void, and neither you nor Unifyd shall be
                                        entitled to arbitrate their dispute. For
                                        more information on JAMS and/or the
                                        rules of JAMS, visit their website at{" "}
                                        <a href="http://www.jamsadr.com">
                                            www.jamsadr.com
                                        </a>
                                        .
                                    </p>
                                    <h2 id="statute-of-limitations">
                                        16. Statute of Limitations
                                    </h2>
                                    <p>
                                        You agree that regardless of any statute
                                        or law to the contrary, any claim
                                        arising out of or related to the
                                        Services must commence within one (1)
                                        year after the cause of action accrues.
                                        Otherwise, such cause of action is
                                        permanently barred. Notwithstanding the
                                        foregoing, this statute of limitations
                                        shall not apply to residents of New
                                        Jersey.
                                    </p>
                                    <h2 id="other">17. Other</h2>
                                    <p>
                                        These TOS constitute the entire and
                                        exclusive understanding and agreement
                                        between us and you regarding the
                                        Services, and these TOS supersede and
                                        replace any and all prior oral or
                                        written understandings or agreements
                                        between us and you regarding such topic.
                                        If we fail to enforce any part of these
                                        TOS, it will not be considered a waiver.
                                        Any amendment to or waiver of these TOS
                                        by us must be made in writing and signed
                                        by us. If any provision of these TOS (or
                                        part of such provision) is found to be
                                        invalid or unenforceable by any court
                                        having competent jurisdiction, then that
                                        provision (or part of that provision)
                                        shall be deemed severable from the TOS
                                        and shall not affect the validity and
                                        enforceability of any remaining
                                        provisions — that invalid or
                                        unenforceable (part of the) clause will
                                        be replaced by a valid and/or
                                        enforceable, as the case may be, (part
                                        of the) clause which is as close to the
                                        intention of the parties as possible.
                                        All of our rights and obligations under
                                        these TOS (including any license rights)
                                        are freely assignable by us in
                                        connection with a merger, acquisition,
                                        or sale of assets, or by operation of
                                        law or otherwise. Nothing in these TOS
                                        shall prevent us from complying with the
                                        law. We reserve all rights not expressly
                                        granted to you.
                                    </p>
                                    <h2 id="questions">18. Questions</h2>
                                    <p>
                                        Should you have any questions regarding
                                        these TOS you may contact us at{" "}
                                        <a href="mailto:info@unifyd.finance">
                                            info@unifyd.finance
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
            </Layout>
        );
    }
}

export default Terms;

export const TermsQuery = graphql`
    query Terms {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;
